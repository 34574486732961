@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Neufreit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/Neufreit-ExtraBold.otf) format('otf');
}

@font-face {
  font-family: 'Marker';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./assets/PermanentMarker-Regular.ttf) format('ttf');
}
.Marker{
  font-family: 'Maker';
}
body {
    font-family: 'Neufreit', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #5b5b5b;
    background-color: #000000;
    margin: 0 auto;
    font-weight: 900;
  }
  
.toggle .right-rotate{
  transform: rotate(45deg) translate(5px, 6px);
}
  
.toggle .middle_hidden{
  opacity: 0;
}
  
.toggle .left-rotate{
  transform: rotate(-45deg) translate(5px, -6px);
}
  
.MytextShadow{
  text-shadow: 4px 5px 0px black;
}

.MyBigShadow{
  text-shadow: 5px 7px 0px black;
}
  